$primary-color: #2596be;
$base-bg: #262a37;

$item-color: #e2f2f9;

$item-active-color: #ffffff;
$item-active-bg: #495867;
$item-active-line-color: $primary-color ;

$item-open-color: #e2f2f9;
$item-open-bg: $primary-color ;

$item-hover-color: #74bcec;
$item-hover-bg: rgba(darken($base-bg, 5%), 0.5);

$icon-color: #54b4dc;
$icon-bg: darken($base-bg, 5%);

$icon-active-color: white;
$icon-active-bg: #5cc0dc;

$icon-open-color: $primary-color;
$icon-open-bg: #ffffff;

$mobile-item-color: #ffffff;
$mobile-item-bg: $primary-color;
$mobile-icon-color: $primary-color ;
$mobile-icon-bg: #ffffff;

$dropdown-color: #ffffff;
$dropdown-bg: lighten($base-bg, 5%);

$header-item-color: rgba($item-color, 0.7);

$toggle-btn-color: #54b4dc;
$toggle-btn-bg: darken($base-bg, 5%);

$item-font-size: 16px;
$item-line-height: 30px;
$item-padding: 10px 15px;
$icon-height: 35px;
$icon-width: 35px;

.v-sidebar-menu.vsm_grey-theme {
    background-color: $base-bg;

    .vsm--link {
        color: $item-color;

        &_active {
            color: $item-active-color;
            background-color: $item-active-bg;
        }

        &_level-1 {
            &.vsm--link_active {
                box-shadow: 3px 0px 0px 0px $item-active-line-color inset;

                & .vsm--icon {
                    color: $icon-active-color;
                    background-color: $icon-active-bg;
                }
            }

            & .vsm--icon {
                background-color: $icon-bg;
            }
        }

        &_hover {
            color: $item-hover-color;
            background-color: $item-hover-bg;
        }

        &_mobile {
            color: $mobile-item-color;
        }
    }

    &.vsm_expanded {
        .vsm--link_level-1 {
            &.vsm--link_open {
                color: $item-open-color;
                background-color: $item-open-bg;

                & .vsm--icon {
                    color: $icon-open-color;
                    background-color: $icon-open-bg;
                }
            }
        }
    }

    &.vsm_collapsed {
        .vsm--link_level-1 {
            &.vsm--link_hover {
                .vsm--icon {
                    color: $mobile-icon-color;
                    background-color: $mobile-icon-bg;
                }
            }
        }
    }

    .vsm--icon {
        color: $icon-color;
    }

    .vsm--dropdown {
        background-color: $dropdown-bg;

        & .vsm--link {
            color: $dropdown-color;
        }

        & .vsm--icon {
            color: $dropdown-color;
        }
    }

    .vsm--mobile-bg {
        background-color: $mobile-item-bg;
    }

    &.vsm_rtl {
        .vsm--link_level-1.vsm--link_active {
            box-shadow: -3px 0px 0px 0px $item-active-line-color inset;
        }
    }

    .vsm--header {
        color: $header-item-color;
    }

    .vsm--badge_default {
        color: $item-color;
        background-color: darken($base-bg, 5%);
    }

    .vsm--toggle-btn {
        color: $toggle-btn-color;
        background-color: $toggle-btn-bg;
    }
}
