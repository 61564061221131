// Font-Awesome
/*@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

/*@import '~bootstrap/scss/bootstrap';
@import "./normalize.scss";

@import '~jquery-ui/themes/base/all.css';*/
@import 'vue-select/dist/vue-select.css';
@import "~@marcoschulte/vue3-progress/dist/";

@import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
@import "grey-theme.scss";
@import 'vue-skeletor/dist/vue-skeletor.css';

@import '@vueform/multiselect/themes/default.css';

@import "skeletor.scss";
// @import "default.scss";

.message-error {
    background: #f94747 !important;
    color: white !important;
}

.message-error svg {
    background: white;
    border-radius: 50%;
}

.message-loading {
    background: #17a2b8 !important;
    color: white !important;
}

.message-loading svg {
    background: white;
    border-radius: 50%;
}

.message-success svg {
    background: white;
    border-radius: 50%;
}

.message-success {
    background: #67C23A !important;
    color: white !important;
}

.message-error .m-message--description {
    color: white !important;
}

.message-success .m-message--description {
    color: white !important;
}

.message-loading .m-message--description {
    color: white !important;
}

.p-55 {
    padding: .764rem;
}


// Styles correspondant au select multiple

:root {
    --ms-font-size: 1rem;
    --ms-line-height: 1.375;
    --ms-bg: #FFFFFF;
    --ms-bg-disabled: #F3F4F6;
    --ms-border-color: #D1D5DB;
    --ms-border-width: 1px;
    --ms-radius: 4px;
    --ms-py: 0.5rem;
    --ms-px: 0.875rem;
    --ms-ring-width: 3px;
    --ms-ring-color: #10B98130;
    --ms-placeholder-color: #9CA3AF;
    --ms-max-height: 10rem;

    --ms-spinner-color: #10B981;
    --ms-caret-color: #999999;
    --ms-clear-color: #999999;
    --ms-clear-color-hover: #000000;

    --ms-tag-font-size: 0.875rem;
    --ms-tag-line-height: 1.25rem;
    --ms-tag-font-weight: 600;
    --ms-tag-bg: #17a2b8;
    --ms-tag-bg-disabled: #9CA3AF;
    --ms-tag-color: #FFFFFF;
    --ms-tag-color-disabled: #FFFFFF;
    --ms-tag-radius: 4px;
    --ms-tag-py: 0.125rem;
    --ms-tag-px: 0.5rem;
    --ms-tag-my: 0.25rem;
    --ms-tag-mx: 0.25rem;

    --ms-tag-remove-radius: 4px;
    --ms-tag-remove-py: 0.25rem;
    --ms-tag-remove-px: 0.25rem;
    --ms-tag-remove-my: 0rem;
    --ms-tag-remove-mx: 0.125rem;

    --ms-dropdown-bg: #FFFFFF;
    --ms-dropdown-border-color: #D1D5DB;
    --ms-dropdown-border-width: 1px;
    --ms-dropdown-radius: 4px;

    --ms-group-label-py: 0.3rem;
    --ms-group-label-px: 0.75rem;
    --ms-group-label-line-height: 1.375;
    --ms-group-label-bg: #E5E7EB;
    --ms-group-label-color: #374151;
    --ms-group-label-bg-pointed: #D1D5DB;
    --ms-group-label-color-pointed: #374151;
    --ms-group-label-bg-disabled: #F3F4F6;
    --ms-group-label-color-disabled: #D1D5DB;
    --ms-group-label-bg-selected: #059669;
    --ms-group-label-color-selected: #FFFFFF;
    --ms-group-label-bg-selected-pointed: #0c9e70;
    --ms-group-label-color-selected-pointed: #FFFFFF;
    --ms-group-label-bg-selected-disabled: #75cfb1;
    --ms-group-label-color-selected-disabled: #D1FAE5;

    --ms-option-font-size: 1rem;
    --ms-option-line-height: 1.375;
    --ms-option-bg-pointed: #FFFFFF;
    --ms-option-color-pointed: #1F2937;
    --ms-option-bg-selected: #10B981;
    --ms-option-color-selected: #FFFFFF;
    --ms-option-bg-disabled: #FFFFFF;
    --ms-option-color-disabled: #D1D5DB;
    --ms-option-bg-selected-pointed: #26C08E;
    --ms-option-color-selected-pointed: #FFFFFF;
    --ms-option-bg-selected-disabled: #FFFFFF;
    --ms-option-color-selected-disabled: #D1FAE5;
    --ms-option-py: 0.5rem;
    --ms-option-px: 0.75rem;

    --ms-empty-color: #4B5563;
}

.pagination {
    margin-top: 20px;
    margin-bottom: 0;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}

.slide-down {
    transition: all 2s ease-out;
}

.avatar {
    font-size: 50px;
    width: 200px;
    height: 200px;
    margin-bottom: 2rem;
}

.status-code {
    font-size: 200px;
}

.status-text {
    font-size: 30px;
}


button,
input,
optgroup,
select,
textarea {
    line-height: normal !important;
}








