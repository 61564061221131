/*@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';*/

@import './pixeden-stroke-icon.css';
/*@import './themify-icon.css';*/

.router-link-active {
    color: white!important;
}

.router-link-active i {
    color: white !important;
}

.metis-menu i {
    color: green!important;
}
